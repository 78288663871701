<template>
  <div v-if="error">
    <h2>エラー</h2>
    <p>エラーコード: {{ error.code }}</p>
    <p>説明: {{ error.message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped>
/* スタイルを適切に設定 */
</style>
