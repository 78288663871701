<template>
  <div class="home">
    <h1>This is a test site.</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";

export default defineComponent({
  name: "HomeView",
  components: {},
  setup() {
    const router = useRoute();
    const meta_robots = ref();

    onMounted(() => {
      if (router.meta.robots) {
        meta_robots.value = router.meta.robots;
      }
    });

    return {
      meta_robots,
    };
  },
});
</script>
