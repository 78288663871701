// store/auth.js

import {
  Commit,
  // Dispatch,
  Module,
  GetterTree,
  ActionTree,
  MutationTree,
} from "vuex";
import Axios from "axios";
Axios.defaults.withCredentials = true;

interface AuthState {
  isLoggedIn: boolean;
  user: any; // ユーザーの型に合わせて調整
}

interface RootState {
  auth: AuthState;
}

const state: AuthState = {
  isLoggedIn: false,
  user: null,
};

const mutations: MutationTree<AuthState> = {
  setLoggedIn(state, loggedIn: boolean) {
    state.isLoggedIn = loggedIn;
  },
  setUser(state, user: any) {
    state.user = user;
  },
};

const actions: ActionTree<AuthState, RootState> = {
  async login(
    { commit }: { commit: Commit },
    { email, password }: { email: string; password: string }
  ) {
    try {
      // CSRF Cookieを取得
      const axios = Axios.create({
        // axiosインスタンスの作成
        baseURL:
          "https://test.hakurintoh.com/laravel/my_service_backend/public/",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        responseType: "json",
      });
      await axios.get("/sanctum/csrf-cookie");

      // ログイン
      // 本来ここにクッキーが必要
      const response = await axios.post("/login", {
        email,
        password,
      });

      // ログイン成功時の処理
      commit("setLoggedIn", true);
      commit("setUser", response.data.user);

      console.log("Login successful", response.data);
      // ログインが必要なページにリダイレクトなどを行う
    } catch (error) {
      console.error("Login error", error);
      // エラー処理を追加
      throw error;
    }
  },
  async logout({ commit }: { commit: Commit }) {
    try {
      // ログアウト
      const response = await Axios.post("/logout");

      // ログアウト成功時の処理
      commit("setLoggedIn", false);
      commit("setUser", null);

      console.log("Logout successful", response.data);
    } catch (error) {
      console.error("Logout error", error);
      // エラー処理を追加
      throw error;
    }
  },
};

const getters: GetterTree<AuthState, RootState> = {
  isLoggedIn: (state) => state.isLoggedIn,
  user: (state) => state.user,
};

const authModule: Module<AuthState, RootState> = {
  namespaced: true, //これがないとダメ
  state,
  mutations,
  actions,
  getters,
};

export default authModule;
