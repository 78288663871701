<template>
  <div class="test">
    <h1>This is an test page</h1>
    <div>オープンなAPIを呼び出すテストです</div>
    <GetGutenberg AuthorYearStart="1801" AuthorYearEnd="1900" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import GetGutenberg from "@/components/GetGutenberg.vue"; // @ is an alias to /src

export default defineComponent({
  name: "TestView",
  components: {
    GetGutenberg,
  },
  beforeRouteEnter(to, from, next) {
    // ログインチェック
    if (!localStorage.getItem("access_token")) {
      // 未ログインの場合、LoginViewにリダイレクト
      next("/login");
    } else {
      // ログイン済みの合場はそのまま続行
      next();
    }
  },
});
</script>
