<template>
  <div>
    <h2>
      {{ AuthorYearStart }}から{{ AuthorYearEnd }}に存命していた人の書籍 from
      Gutendex
    </h2>
    <p>page 1</p>

    <details>
      <summary>Raw Response</summary>
      <code>
        <pre class="rawcode">{{ books }}</pre>
      </code>
    </details>
    <!-- 表示するもの：title, author(複数), "languages" -->
    <table>
      <thead>
        <th>Title</th>
        <th>Authors</th>
        <th>Languages</th>
      </thead>
      <tbody>
        <tr v-for="book in books" :key="book.key">
          <td>{{ book.title }}</td>
          <td>
            <span v-for="author in book.authors" :key="author.key">{{
              author.name
            }}</span>
          </td>
          <td>{{ book.languages }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <ErrorComponent :error="error" />
</template>

<style scoped lang="scss">
.rawcode {
  background-color: gainsboro;
  text-align: left;
}
</style>

<script lang="ts">
import axios from "axios";
import ErrorComponent from "@/components/ErrorComponent.vue";

export default {
  data() {
    return {
      books: [] as string[],
      error: null,
    };
  },
  components: {
    ErrorComponent,
  },
  props: {
    AuthorYearStart: String,
    AuthorYearEnd: String,
  },
  mounted() {
    // let books = [] as string[];
    const url =
      `https://gutendex.com/books/?&author_year_start=${this.AuthorYearStart}&author_year_end=${this.AuthorYearEnd}` as string;

    axios
      .get(url, {
        headers: {
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        this.books = response.data.results;
        // return books;
      })
      .catch((error) => {
        console.error("APIリクエストエラー:", error);
        this.error = error;
      });
  },
} as any;
</script>
