<template>
  <div>
    <form @submit.prevent="prelogin">
      <div>
        <label>Eメール</label>
        <input type="text" v-model="form.email" />
      </div>
      <div>
        <label>パスワード</label>
        <input type="password" v-model="form.password" />
      </div>
      <button>ログイン</button>
    </form>
  </div>
  <ErrorComponent :error="error" />
</template>

<script type="ts">
// import axios from "axios";
import ErrorComponent from "@/components/ErrorComponent.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    ...mapActions("auth", {
      login: "login",
    }),

    async prelogin() {
      try {
        // ログインアクションを呼び出す
        await this.login({
          email: this.form.email,
          password: this.form.password,
        });

        // ログイン後の処理を追加（例えばリダイレクトなど）
      } catch (error) {
        console.error("Login error", error);
        // エラー処理を追加
        this.error = error;
      }
    },
  },
  components: {
    ErrorComponent,
  },
};
</script>
